import React, { useState } from 'react';

const AddHouseModal = ({ showModal, setShowModal, setHouses, token, userId }) => {
    const [newHouseName, setNewHouseName] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/newHouse`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name: newHouseName, user_id: userId }),
            });

            if (!response.ok) {
                throw new Error('Failed to add house');
            }

            const addedHouse = await response.json();
            setHouses((prevRooms) => [...prevRooms, addedHouse]); // Aktualizujeme izby
            setNewHouseName('');
            setSuccess('Dom bol úspešne pridaný!');
            setTimeout(() => {
                setShowModal(false);
                setError('');
                setSuccess('');
            }, 1500);
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <>
            {showModal && (
                <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
                    <div className="relative p-4 w-full max-w-2xl max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <div className="flex items-center justify-between p-4 border-b rounded-t dark:border-gray-600">
                                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                    Pridajte nový dom
                                </h3>
                                <button 
                                    onClick={() => setShowModal(false)} 
                                    type="button" 
                                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                >
                                    <svg className="w-3 h-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1l6 6m0 0l6 6M7 7L1 13m6-6l6-6"/>
                                    </svg>
                                    <span className="sr-only">Zatvoriť modal</span>
                                </button>
                            </div>
                            {error && <p className="text-red-500">{error}</p>}
                            {success && <p className="text-green-500">{success}</p>}
                            <div className="p-4 space-y-4">
                                <form onSubmit={handleSubmit}>
                                    <label className="block font-medium mb-2" htmlFor="newRoom">
                                        Názov nového domu:
                                    </label>
                                    <input
                                        id="newHouse"
                                        type="text"
                                        value={newHouseName}
                                        onChange={(e) => setNewHouseName(e.target.value)}
                                        className="border p-2 rounded w-full text-black"
                                        placeholder="Zadajte názov"
                                    />
                                    <div className="flex justify-end mt-4">
                                        <button 
                                            type="submit" 
                                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                        >
                                            Pridať
                                        </button>
                                        <button 
                                            type="button" 
                                            onClick={() => setShowModal(false)} 
                                            className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                        >
                                            Zrušiť
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default AddHouseModal;
