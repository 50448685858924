import React, { useEffect, useState } from 'react';

const HouseInvitationModal = ({ showModal, setShowModal, token, houseId }) => {
    const [newUserEmail, setNewUserEmail] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [sharedUsers, setSharedUsers] = useState([]);

    useEffect(() => {
        const SharedUsers = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/${houseId}/housesShared`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch users');
                }

                const data = await response.json();
                setSharedUsers(data.shared_users);
            } catch (err) {
                setError(err.message);
            }
        };
        if (houseId != null){
            SharedUsers();
        }
    }, [token, houseId]);

    const validateEmail = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(newUserEmail) || newUserEmail === '') {
            setError('Zadajte platný email');
        } else {
            setError('');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${houseId}/inviteUser`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: newUserEmail, role: "guest" }),
            });

            if (!response.ok) {
                if(response.status === 404){
                    throw new Error('Failed to send invitation. Email not found.');
                }
                throw new Error('Failed to send invitation.');
            }

            setSuccess('User has been invited.');
            setTimeout(() => {
                setShowModal(false);
                setError('');
                setSuccess('');
            }, 1500);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleDelete = async (userId, houseId) => {
        setError('');
        setSuccess('');
    
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/sharedUserDelete`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ houseId: houseId, userId: userId }),
            });
    
            if (!response.ok) {
                if (response.status === 404) {
                    throw new Error('Failed to delete guest user. User not found.');
                }
                throw new Error('Failed to delete user.');
            }
    
            setSharedUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
    
            setSuccess('User has been deleted.');
            setTimeout(() => {
                setError('');
                setSuccess('');
            }, 1500);
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <>
            {showModal && (
                <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
                    <div className="relative p-4 w-full max-w-2xl max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 p-2">
                            <div className="flex items-center justify-between p-4 border-b rounded-t dark:border-gray-600">
                                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                    User invitation
                                </h3>
                                <button
                                    onClick={() => setShowModal(false)}
                                    type="button"
                                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                >
                                    <svg className="w-3 h-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1l6 6m0 0l6 6M7 7L1 13m6-6l6-6"/>
                                    </svg>
                                    <span className="sr-only">Zatvoriť modal</span>
                                </button>
                            </div>
                            {error && <p className="text-red-500">{error}</p>}
                            {success && <p className="text-green-500">{success}</p>}

                            <div className="p-4 space-y-4">
                            {sharedUsers.length > 0 ? (
                                sharedUsers.map((user) => (
                                    <li key={user.id} className="mb-4 flex items-center justify-between dark:hover:bg-gray-500">
                                        <span><strong>{user.name}</strong> - {user.email}</span>
                                        <button
                                            onClick={() => { handleDelete(user.id, user.pivot.house_id)}}
                                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                        >
                                            <svg className="w-3 h-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1l6 6m0 0l6 6M7 7L1 13m6-6l6-6"/>
                                            </svg>
                                        </button>
                                    </li>
                                ))
                                ) : (
                                    <></>
                                )}
                                <form onSubmit={handleSubmit}>
                                    <label className="block font-medium mb-2" htmlFor="newRoom">
                                        User email:
                                    </label>
                                    <input
                                        id="newHouse"
                                        type="text"
                                        onChange={(e) => setNewUserEmail(e.target.value)}
                                        onBlur={validateEmail}
                                        className="border p-2 rounded w-full text-black"
                                        placeholder="example@example.com"
                                    />
                                    <div className="flex justify-end mt-4">
                                        <button 
                                            type="submit" 
                                            className={`text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 ${
                                                error ? 'opacity-50 cursor-not-allowed' : ''
                                            }`}
                                            disabled={!!error}
                                        >
                                            Invite
                                        </button>
                                        <button 
                                            type="button" 
                                            onClick={() => setShowModal(false)} 
                                            className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                            
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default HouseInvitationModal;
