import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../AuthContext';

const RoomCard = ({ houseId, roomId, roomName, showDelete, onDelete }) => {
    const [devices, setDevices] = useState([]); // Zoznam zariadení
    const [role, setRole] = useState(null);
    const [sensorData, setSensorData] = useState({}); // Senzorové dáta pre každé zariadenie
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { token } = useAuth();

    useEffect(() => {
        const fetchRoomDetails = async () => {
            try {
                // Získaj detaily izby a zoznam zariadení
                const roomResponse = await fetch(`${process.env.REACT_APP_API_URL}/room/${roomId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (!roomResponse.ok) {
                    throw new Error('Failed to fetch room details');
                }

                const roomData = await roomResponse.json();
                setRole(roomData.role);
                if (roomData.room.devices && roomData.room.devices.length > 0) {
                    setDevices(roomData.room.devices); // Ulož zariadenia do state

                    // Pre každé zariadenie načítaj senzorové údaje
                    const sensorDataPromises = roomData.room.devices.map(async (device) => {
                        const sensorResponse = await fetch(`${process.env.REACT_APP_API_URL}/sensorData/${device.id}`, {
                            method: 'GET',
                            headers: {
                                'Authorization': `Bearer ${token}`,
                            },
                        });

                        if (!sensorResponse.ok) {
                            if (sensorResponse.status === 404) {
                                return { deviceId: device.id, data: null };
                            }
                            throw new Error('Failed to fetch sensor data');
                        }

                        const sensorData = await sensorResponse.json();
                        return { deviceId: device.id, data: sensorData }; // Vráť senzorové dáta s deviceId
                    });

                    // Počkám na všetky senzorové dáta a uložím ich
                    const allSensorData = await Promise.all(sensorDataPromises);
                    const sensorDataMap = {};
                    allSensorData.forEach(({ deviceId, data }) => {
                        sensorDataMap[deviceId] = data; // Uložím dáta podľa deviceId
                    });
                    setSensorData(sensorDataMap);
                } else {
                    setDevices([]); // Ak nie sú zariadenia
                }

                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };

        fetchRoomDetails();
    }, [houseId, roomId, token]);

    if (loading) {
        // return <p>Loading sensor data...</p>;
        return (
            <div role="status" className="flex items-center block justify-center min-h-40  bg-gray-300 rounded-lg animate-pulse dark:bg-gray-700">
                <svg aria-hidden="true" className="inline w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                </svg>
                <span className="sr-only">Loading...</span>
            </div>
        )
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <section>
            {showDelete ? (
                
                <section className="card">
                    <button className="absolute inline-flex items-center justify-center w-8 h-8 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900 hover:scale-150 duration-150"
                        onClick={onDelete} >
                        <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12"/>
                        </svg>
                    </button>

                    <h5 className="cardTitle"><strong>{roomName}</strong></h5>
                    {devices.length > 0 ? (
                        <div className="devices">
                            {devices.map((device) => (
                                <div key={device.id} className="pb-1">
                                    <p className='text-xl'><strong>{device.name}</strong></p>
                                    {sensorData[device.id] ? (
                                        sensorData[device.id].map((sensor, index) => (
                                            <p key={index}>
                                                <strong>{sensor.label}:</strong> {sensor.value} {sensor.unit}
                                            </p>
                                        ))
                                    ) : (
                                        <p>No sensor data available for this device.</p>
                                    )}
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p>No devices available in this room.</p>
                    )}
                </section>
            ) : (
                <Link to={`/house/${houseId}/room/${roomId}`} className="card">
                <h5 className="cardTitle"><strong>{roomName}</strong></h5>
                {devices.length > 0 ? (
                    <div className="devices">
                        {devices.map((device) => (
                            <div key={device.id} className="pb-1">
                                <p className='text-xl'><strong>{device.name}</strong></p>
                                {sensorData[device.id] ? (
                                    sensorData[device.id].map((sensor, index) => (
                                        <p key={index}>
                                            <strong>{sensor.label}:</strong> {sensor.value} {sensor.unit}
                                        </p>
                                    ))
                                ) : (
                                    <p>No sensor data available for this device.</p>
                                )}
                            </div>
                        ))}
                    </div>
                ) : (
                    <p>No devices available in this room.</p>
                )}
            </Link>
            )}
            
        </section>
    );
};

export default RoomCard;
