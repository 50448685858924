import React, { useState } from 'react';
import PowerButton from '../PowerButton';

const SettingsModal = ({ showModal, setShowModal, deviceSettings, deviceId }) => {
    const [settings, setSettings] = useState(deviceSettings || {});
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    // Funkcia na zmenu nastavení (textových polí)
    const handleChange = (e) => {
        const { name, value } = e.target;
        setSettings({ ...settings, [name]: value });
    };

    // Funkcia na prepnutie stavu PowerButton a aktualizáciu nastavení
    const handlePowerToggle = (newPowerState) => {
        setSettings({ ...settings, power: newPowerState ? 1 : 0 });
    };

    // Funkcia na uloženie zmien do backendu
    const handleSave = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/deviceSettings/${deviceId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ settings })
            });

            if (!response.ok) {
                throw new Error('Failed to update settings');
            }

            setSuccess('Settings updated successfully');
            setTimeout(() => {
                setShowModal(false); // Zatvorenie modálneho okna po uložení
            }, 2000);
        } catch (error) {
            setError('Error saving settings');
            console.error('Error saving settings:', error);
        }
    };
    
    return (
        <section>
            {showModal && (
                <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
                    <div className="relative p-4 w-full max-w-2xl max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 p-2">
                            <div className="flex items-center justify-between p-4 border-b rounded-t dark:border-gray-600">
                                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                    Device settings
                                </h3>
                                
                                {   
                                    deviceSettings.hasOwnProperty("power") ? (
                                        <PowerButton power={settings.power === 1}  onToggle={handlePowerToggle} label={true} />
                                      
                                    ) :(
                                        <></>
                                    )
                                }

                                <button
                                    onClick={() => setShowModal(false)}
                                    type="button"
                                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                >
                                    <svg className="w-3 h-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1l6 6m0 0l6 6M7 7L1 13m6-6l6-6"/>
                                    </svg>
                                    <span className="sr-only">Zatvoriť modal</span>
                                </button>
                            </div>

                            {error && <p className="text-red-500">{error}</p>}
                            {success && <p className="text-green-500">{success}</p>}
                            
                            <section className='py-3 px-10'>
                                {Object.keys(settings).map((key, index) => (
                                key === "power" ? (
                                    // <PowerButton 
                                    //     key={index} 
                                    //     power={settings[key] === 1} 
                                    //     onToggle={handlePowerToggle} 
                                    // />
                                    <div key={index}></div>
                                ) : (
                                    <div key={index} className="relative">
                                        <input
                                            className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-lg text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                            id="floating_filled"
                                            type="text"
                                            name={key}
                                            value={settings[key]}
                                            placeholder=""
                                            onChange={handleChange}
                                            required
                                        />
                                        <label 
                                            htmlFor="floating_filled" 
                                            className="absolute text-xl text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
                                        >
                                            {key}:
                                        </label>
                                    </div>
                                )
                            ))}

                            </section>
                            <section className='p-3'>
                                <button type="submit" className="mainButton" onClick={handleSave}>Uložiť</button>
                                <button className="mainButton"onClick={() => setShowModal(false)}>Zatvoriť</button>
                            </section>
                        </div>
                    </div>
                </div> 
            )}
        </section>
    );
};

export default SettingsModal;
