// import React, { useEffect } from 'react';
// import { useAuth } from "./AuthContext";
// import { useNavigate } from "react-router-dom";

const NotVerified = () => {
    // const navigate = useNavigate();
    // const { logout } = useAuth();

    // useEffect(() => {
    //     // Odhlásenie používateľa
    //     logout();

    //     // Presmerovanie na /login po 3 sekundách
    //     const timer = setTimeout(() => {
    //         navigate('/login');
    //     }, 3000); // 3000 ms = 3 sekundy

    //     // Vyčistenie timeru pri unmountovaní
    //     return () => clearTimeout(timer);
    // }, [logout, navigate]);

    return (
        <div>
            <h1>Your email address is not verified.</h1>
            <p>Potvrd email</p>
        </div>
    );
};

export default NotVerified;
