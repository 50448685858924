import React, { useState } from 'react';
import Datepicker from "react-tailwindcss-datepicker"; 

const DateRangePicker = ({ onApply }) => {
    const yesterday = new Date();
    yesterday.setDate(new Date().getDate() - 1);

    const [value, setValue] = useState({ 
        startDate: yesterday, 
        endDate: new Date()
    });

    const MIN_DATE = new Date();
    MIN_DATE.setDate(MIN_DATE.getDate() - 4);

    const MAX_DATE = new Date();
    MAX_DATE.setDate(MAX_DATE.getDate() + 4);

    const handleApply = (newValue) => {
        console.log("HANDLE Apply NEW START DATE: ", newValue.startDate);
        console.log("HANDLE Apply NEW END DATE: ", newValue.endDate);
        if (onApply) {
            onApply(newValue); // Použitie newValue priamo
        }
    };

    return (
        <Datepicker
            className="dark:hover:bg-gray-700"
            separator="to"
            maxDate={MAX_DATE}
            startWeekOn="mon"
            value={value}
            onChange={newValue => {
                setValue(newValue);
                handleApply(newValue); // Volanie handleApply s priamo novou hodnotou
            }}
            showShortcuts={false}
        /> 
    );
};

export default DateRangePicker;
