// import logo from './logo.svg';
// import './App.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {ThemeProvider} from "./components/ThemeContext";
import {AuthProvider} from "./components/AuthContext";
import PrivateRoute from "./components/PrivateRoute";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import Logout from "./components/logout";
import Register from "./components/Register";
import HouseDetail from './components/HouseDetail';
import Room from "./components/Room";
import Houses from "./components/Houses";
import Device from "./components/Device";
// import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <AuthProvider>
            <ThemeProvider>
                  <section className='w-full min-h-screen bg-myLight text-myDarkBlue dark:bg-myDarkBlue dark:text-myLight duration-300'>
                      <Routes>
                          <Route path="/register" element={<Register/>}/>
                          <Route path="/login" element={<Login/>}/>
                          <Route path="/logout" element={<Logout/>}/>
                          <Route path="/" element={<PrivateRoute element={<Dashboard/>}/>}/>
                          <Route path="/house/:id" element={<PrivateRoute element={<HouseDetail/>}/>}/> {/* Detail domu */}
                          <Route path="/house/:houseId/room/:roomId" element={<PrivateRoute element={<Room/>}/>}/> {/* Detail domu */}
                          <Route path="/houses" element={<PrivateRoute element={<Houses/>}/>}/>
                          <Route path="/device/:deviceId" element={<PrivateRoute element={<Device/>}/>}/>
                      </Routes>
                    {/* <Footer></Footer> */}
                  </section>
            </ThemeProvider>
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
