import React, { useEffect } from 'react';
import { useAuth } from "./AuthContext";
import { useNavigate } from "react-router-dom";

const Logout = () => {
    const navigate = useNavigate();
    const { logout } = useAuth();

    useEffect(() => {
        // Odhlásenie používateľa
        logout();

        // Presmerovanie na /login po 3 sekundách
        const timer = setTimeout(() => {
            navigate('/login');
        }, 3000); // 3000 ms = 3 sekundy

        // Vyčistenie timeru pri unmountovaní
        return () => clearTimeout(timer);
    }, [logout, navigate]);

    return (
        <div>
            <h1>Logout!</h1>
            <p>Odhlásili ste sa. Budete presmerovaní na prihlásenie...</p>
        </div>
    );
};

export default Logout;
