import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleRegister = async (e) => {
        e.preventDefault();
        const api_url = process.env.REACT_APP_API_URL;  // Uisti sa, že máš správne API URL

        try {
            const response = await fetch(`${api_url}/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: name,
                    email: email,
                    password: password,
                }),
            });

            const data = await response.json();

            if (!response.ok) {
                let msg = [];
                if (data.name?.[0]) {
                    msg.push(data.name[0]);
                }
                if (data.email?.[0]) {
                    msg.push(data.email[0]);
                }
                if (data.password?.[0]) {
                    msg.push(data.password[0]);
                }
                setError(msg);
                return;
            }

            navigate('/login');  // Po úspešnej registrácii presmeruj na prihlasovaciu stránku
        } catch (err) {
            setError('Chyba pri komunikácii so serverom');
        }
    };

    return (
        <div className="max-w-md mx-auto p-4">
            <h2 className="text-2xl font-bold mb-4">Registrácia</h2>
            {error && error.map((err, index) => (
                <p key={index} className="text-red-500">{err}</p>
            ))}
            <form onSubmit={handleRegister}>
                <div className="mb-4">
                    <label htmlFor="name" className="block mb-1">Meno</label>
                    <input
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="w-full p-2 dark:text-black border rounded"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="email" className="block mb-1">Email</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full p-2 dark:text-black border rounded"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="password" className="block mb-1">Heslo</label>
                    <p className="text-red-500">
                        {password.length > 0 && password.length < 8 && "The password field must be at least 8 characters."}
                    </p>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full p-2 dark:text-black border rounded"
                        minLength="8"
                        required
                    />
                </div>
                <button
                    type="submit"
                    className="bg-blue-500 text-white py-2 px-4 rounded"
                >
                    Zaregistrovať sa
                </button>
            </form>
        </div>
    );
};

export default Register;
