// import React, { useEffect, useState } from 'react';
import DeviceChartHour from "./DeviceChartHour";

const RoomCharts = ({room, devices}) => {

    return (
        <ul className="grid grid-cols-1 gap-4 lg:grid-col-2">
            {devices && devices.length > 0 ? (
                devices.map((device) => (
                    <DeviceChartHour key={device.id} device={device} />
                ))
            ) : (
                <li>No devices.</li>
            )}
        </ul>
    );
};

export default RoomCharts;
