import React, { useEffect, useState } from 'react';

const AddDeviceChartModal = ({ showModal, setShowModal, setRooms, token, houseId }) => {
    const [newDeviceName, setNewDeviceName] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [selectedDeviceType, setSelectedDeviceType] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [newDeviceId, setNewDeviceId] = useState('');
    const [deviceTypes, setDeviceTypes] = useState([]);
    const [scanning, setScanning] = useState(false); // Stav skenovania QR kódu

    const handleDeviceTypeChange = (e) => {
        setSelectedDeviceType(e.target.value);
    };

    const handleCreateDevice = async (e) => {
        e.preventDefault();
        setError('');
        setSuccessMessage('');

        if (!selectedDeviceType) {
            setError('Vyplňte všetky polia');
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/dashboard/devices`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    device_id: selectedDeviceType,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to create device');
            }

            const data = await response.json();
            setSuccessMessage(`Zariadenie ${data.name} bolo úspešne vytvorené`);
            setNewDeviceName('');
            setSelectedDeviceType('');
            setNewDeviceId('');
        } catch (err) {
            setError(err.message);
        }
    };

    useEffect(() => {
        const fetchDeviceTypes = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/devices/userDashboard`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch device types');
                }

                const data = await response.json();
                setDeviceTypes(data);
            } catch (err) {
                setError(err.message);
            }
        };

        fetchDeviceTypes();
    }, [token]);

    const handleScan = (data) => {
        if (data) {
            setNewDeviceId(data.text);
            setScanning(false);
        }
    };

    const handleError = (err) => {
        setError('Failed to scan QR code');
        console.error(err);
    };

    return (
        <>
            {showModal && (
                <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
                    <div className="relative p-4 w-full max-w-2xl max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 p-2">
                            <div className="flex items-center justify-between p-4 border-b rounded-t dark:border-gray-600">
                                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                    Pridajte Device
                                </h3>
                                <button
                                    onClick={() => setShowModal(false)}
                                    type="button"
                                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                >
                                    <svg className="w-3 h-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1l6 6m0 0l6 6M7 7L1 13m6-6l6-6"/>
                                    </svg>
                                    <span className="sr-only">Zatvoriť modal</span>
                                </button>
                            </div>
                            {error && <p className="text-red-500">{error}</p>}
                            {success && <p className="text-green-500">{success}</p>}
                            <div className="m-4">
                                <form onSubmit={handleCreateDevice}>
                                    <div className="mb-4">
                                        <label htmlFor="deviceType" className="block font-medium mb-2">
                                            Vyberte typ zariadenia:
                                        </label>
                                        <select
                                            id="deviceType"
                                            value={selectedDeviceType}
                                            onChange={handleDeviceTypeChange}
                                            className="border p-2 rounded w-full text-black"
                                        >
                                            <option value="">-- Vyberte zariadenie --</option>
                                            {deviceTypes.map((deviceType) => (
                                                <option key={deviceType.id} value={deviceType.id}>
                                                    {deviceType.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    {selectedDeviceType && (
                                        <button type="submit" className="bg-blue-500 text-white py-2 px-4 mb-2 mt-4 rounded">
                                            Pridať zariadenie
                                        </button>
                                    )}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default AddDeviceChartModal;
