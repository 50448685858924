import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { useAuth } from './AuthContext';
import io from 'socket.io-client';

NoDataToDisplay(Highcharts);

const DeviceChartHour = ({ device, showDelete, onDelete }) => {
    const { token } = useAuth();
    const [sensorData, setSensorData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSensorData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/sensor/${device.id}/data/last-hour`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const data = await response.json();

                if (Array.isArray(data)) {
                    setSensorData(data);
                } else {
                    setSensorData([]);
                }
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch sensor data');
                setLoading(false);
            }
        };

        fetchSensorData();

        // Pripojenie k WebSocket serveru
        const socket = io('https://socketio-service.onrender.com', { transports: ['websocket'] });

        // Pripojenie k špecifickému kanálu podľa device_id
        socket.on(device.id, (newData) => {
            if (newData.device_id === device.id) {
                const timestamp = convertToBratislavaTime(new Date().toISOString());
                const formattedData = {
                    ...newData,
                    created_at: timestamp,
                    value: parseFloat(newData.value)
                };
                setSensorData((prevData) => [...prevData, formattedData]);
            }
        });

        return () => {
            socket.disconnect();
        };
    }, [device.id, token]);

    // Funkcia na konverziu UTC času na časové pásmo Europe/Bratislava
    const convertToBratislavaTime = (utcDate) => {
        const date = new Date(utcDate);
        return date.getTime() + date.getTimezoneOffset() * 60000 + 3600000; // Posun pre UTC+1
    };

    // Rozdelenie dát podľa `label`
    const seriesData = sensorData.reduce((acc, item) => {
        const { label, value, created_at, unit } = item;
        const timestamp = convertToBratislavaTime(created_at);

        if (!acc[label]) {
            acc[label] = { name: label, data: [], unit: unit, tooltip: { valueSuffix: ' ' + unit } };
        }

        acc[label].data.push([timestamp, parseFloat(value)]);
        return acc;
    }, {});

    const options = {
        credits: { enabled: false },
        lang: { noData: 'No data available' },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#748D92',
            },
        },
        chart: {
            backgroundColor: 'rgba(33,42,49,0.27)',
            type: 'spline',
            zoomType: 'x',
        },
        time: { timezone: 'Europe/Bratislava' },
        boost: {
            useGPUTranslations: true,
            usePreAllocated: true,
        },
        accessibility: { enabled: false },
        title: {
            text: `${device.name}`,
            style: { color: '#fff' },
        },
        xAxis: {
            type: 'datetime',
            title: { text: 'Time' },
            labels: { style: { color: '#fff' } },
        },
        yAxis: {
            title: { text: `${Object.values(seriesData)[0]?.unit || ''}` },
            labels: { style: { color: '#fff' } },
        },
        series: Object.values(seriesData),
        legend: { itemStyle: { color: '#fff' } },
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p className="text-red-500">{error}</p>;

    return (
        <section id="card p-0" className="card">
            {showDelete && (
                <button
                    className="absolute inline-flex items-center justify-center w-8 h-8 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900 hover:scale-150 duration-150"
                    onClick={onDelete}
                >
                    <svg
                        className="w-4 h-4"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 10"
                    >
                        <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M1 5h12"
                        />
                    </svg>
                </button>
            )}
            <HighchartsReact highcharts={Highcharts} options={options} />
        </section>
    );
};

export default DeviceChartHour;
