import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { useAuth } from './AuthContext';
import DateRangePicker from './DateRangePicker';

NoDataToDisplay(Highcharts);

const DeviceChart = ({ device }) => {
    const { token } = useAuth();
    const [sensorData, setSensorData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState(new Date());

    const handleDateRangeApply = (value) => {
        const { startDate, endDate } = value;
        setStartDate(startDate);
        setEndDate(endDate);

        fetchDataForDateRange(startDate, endDate);
    };

    // Spoločná funkcia na spracovanie a nastavenie dát pre graf
    const processSensorData = (data) => {
        const seriesData = data.reduce((acc, item) => {
            const { label, value, created_at, unit } = item;
            const timestamp = convertToBratislavaTime(created_at);

            if (!acc[label]) {
                acc[label] = { name: label, data: [], unit: unit, tooltip: { valueSuffix: ' ' + unit } };
            }

            acc[label].data.push([timestamp, parseFloat(value)]);
            return acc;
        }, {});
        setSensorData(Object.values(seriesData));
    };

    const fetchDataForDateRange = async (startDate, endDate) => {
        try {
            setLoading(true);
            const formattedStartDate = new Date(startDate).toISOString();
            const formattedEndDate = new Date(endDate).toISOString();

            // Volanie API s filtrovanými dátumami
            const response = await fetch(`${process.env.REACT_APP_API_URL}/sensor/${device.id}/data`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ startDate: formattedStartDate, endDate: formattedEndDate })
            });

            const data = await response.json();
            if (Array.isArray(data)) {
                processSensorData(data);
            } else {
                setSensorData([]);
            }
        } catch (err) {
            setError('Failed to fetch sensor data');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const fetchSensorData = async () => {
            try {
                setLoading(true);
                const response = await fetch(`${process.env.REACT_APP_API_URL}/sensor/${device.id}/data/last-hour`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                if (Array.isArray(data)) {
                    processSensorData(data);
                } else {
                    setSensorData([]);
                }
            } catch (err) {
                setError('Failed to fetch sensor data');
            } finally {
                setLoading(false);
            }
        };

        fetchSensorData();
    }, [device.id, token]);

    // Konverzia UTC na Europe/Bratislava
    const convertToBratislavaTime = (utcDate) => {
        const date = new Date(utcDate);
        return date.getTime() + date.getTimezoneOffset() * 60000 + 3600000;
    };

    const options = {
        credits: { enabled: false },
        lang: { noData: 'No data available' },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#748D92',
            },
        },
        chart: {
            backgroundColor: 'rgba(33,42,49,0.27)',
            type: 'spline',
            zoomType: 'x',
        },
        time: { timezone: 'Europe/Bratislava' },
        title: {
            text: `${device.name}`,
            style: { color: '#fff' },
        },
        xAxis: {
            type: 'datetime',
            title: { text: 'Time' },
            labels: { style: { color: '#fff' } },
        },
        yAxis: {
            title: { text: `${sensorData[0]?.unit || ''}` },
            labels: { style: { color: '#fff' } },
        },
        series: sensorData,
        legend: { itemStyle: { color: '#fff' } },
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p className="text-red-500">{error}</p>;

    return (
        <section className="card">
            <DateRangePicker onApply={handleDateRangeApply} />
            <HighchartsReact highcharts={Highcharts} options={options} />
        </section>
    );
};

export default DeviceChart;
