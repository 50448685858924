import React, { useState, useEffect, useContext } from 'react';
import { useAuth } from "./AuthContext";
import { useNavigate } from "react-router-dom";
import ThemeContext from "./ThemeContext";

const Login = () => {
    const { login, isAuthenticated } = useAuth();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    // const [isDarkMode, setIsDarkMode] = useState(true); // Defaultne tmavý režim
    const {isDarkMode, toggleTheme} = useContext(ThemeContext);

    // const toggleTheme = () => {
    //     setIsDarkMode(!isDarkMode);
    // };

    useEffect(() => {
        if (isAuthenticated) {
            navigate("/"); // Presmeruj na dashboard, ak je používateľ už prihlásený
        }
    }, [isAuthenticated, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const api_url = process.env.REACT_APP_API_URL;

            const response = await fetch(`${api_url}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    email: username,
                    password: password,
                }),
            });

            if (!response.ok) {
                if (response.status === 401) {
                    throw new Error('Invalid email or password.');
                } else if (response.status === 422) {
                    const errorData = await response.json();
                    throw new Error(errorData.message || 'Validation error.');
                }
                const errorData = await response.json(); // Načítaj chybové dáta
                throw new Error(errorData.message || 'Something went wrong!'); // Vytvor chybu
            }

            const data = await response.json();
            login(data.access_token, data.expires_at, data.user_id); // Ulož token
            navigate("/");
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        // <div className={`min-h-screen flex ${isDarkMode ? 'bg-myDarkBlue text-myLight' : 'bg-white text-myDarkBlue'}`}>
        <div className= "mainContainer">
        {/* Pravá strana s prihlasovacím formulárom */}
        <div className="loginCard">
            <h2 className="text-3xl font-bold mb-8">
                Prihlásenie
            </h2>
            {error && <p className="text-red-500 mb-4">{error}</p>}
            <form className="w-full max-w-sm" onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-sm font-bold mb-2">
                        Používateľské meno
                    </label>
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        // className={` ${isDarkMode ? '' : ''} `}
                        className="myInput"
                        placeholder="Zadajte používateľské meno"
                    />
                </div>
                <div className="mb-6">
                    <label className="block text-sm font-bold mb-2">
                        Heslo
                    </label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        // className={`w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 ${isDarkMode ? 'bg-myGrey text-myLight' : 'bg-white text-black'} focus:ring-myBlue`}
                        className="myInput"
                        placeholder="Zadajte heslo"
                    />
                </div>
                <button
                    type="submit"
                    className="loginButton"
                >
                    Prihlásiť sa
                </button>
            </form>

            {/* Tlačidlo Registrovať sa */}
            <div className="mt-4">
                <p>Nemáte účet?</p>
                <button
                    onClick={() => navigate('/register')}
                    className={`w-full py-2 px-4 mt-2 rounded font-bold ${isDarkMode ? 'bg-gray-500 text-white hover:bg-gray-600' : 'bg-myLightBlue text-myDarkBlue hover:bg-myBlue'}`}
                    // className="mainButton"
                >
                    Registrovať sa
                </button>
            </div>

            {/* Prepnutie témy */}
            <div className="mt-6">
                <button
                    onClick={toggleTheme}
                    className="p-2 rounded-full bg-gray-200 dark:bg-gray-700 transition-colors duration-300 absolute top-4 right-4 sm:static hover:scale-110"
                >
                    <svg className="w-6 h-6 text-gray-800 dark:text-gray-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"></path>
                    </svg>
                </button>
            </div>
        </div>
    </div>

    );
};

export default Login;
