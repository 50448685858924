import React from 'react';

const PowerButton = ({ power, onToggle, label }) => {
    const handleToggle = () => {
        // Zvolanie funkcie na prepínanie stavu
        onToggle(!power);
    };

    return (
        <label className="inline-flex items-center cursor-pointer">
            <input
                type="checkbox"
                checked={power} // Kontroluj hodnotu power
                onChange={(e) => handleToggle(e.target.checked)} // Odošli novú hodnotu na zmenu
                className="sr-only peer"
            />
            {/*<span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Toggle me</span>*/}
            {label ? (
                <strong>Power: </strong>
            ) :(
                <></>
            )}
            <div
                className={`relative w-11 mx-3 h-6 rounded-full peer peer-focus:ring-4 peer-focus:ring-red-300 dark:peer-focus:ring-myLightBlue 
                    ${power ? 'bg-green-500' : 'bg-red-600 dark:bg-gray-700'} 
                    peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white 
                    after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 
                    after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600`}
            ></div>
        </label>
    );
};

export default PowerButton;
