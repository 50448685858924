import React, { createContext, useState, useContext, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [token, setToken] = useState(null);
    const [expiresAt, setExpiresAt] = useState(null);
    const [userId, setUserId] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const logoutRef = useRef(null);

    const refreshingRef = useRef(false); 

    const isTokenExpired = (expiresAt) => {
        if (!expiresAt) return true;
        return new Date(expiresAt) <= new Date();
    };

    const login = (token, expiresAt, userId) => {
        setIsAuthenticated(true);
        setToken(token);
        setExpiresAt(expiresAt);
        localStorage.setItem('authToken', token);
        localStorage.setItem('expiresAt', new Date(expiresAt).toISOString());
        localStorage.setItem('userID', userId);
    };

    const logout = useCallback(() => {
        setIsAuthenticated(false);
        setToken(null);
        setExpiresAt(null);
        localStorage.removeItem('authToken');
        localStorage.removeItem('expiresAt');
        localStorage.removeItem('userID');
        navigate('/login');
    }, [navigate]);

    logoutRef.current = logout;

    useEffect(() => {
        if (expiresAt) {
            const timeout = setTimeout(() => {
                if (isTokenExpired(expiresAt)) logoutRef.current();
            }, new Date(expiresAt) - new Date());
            return () => clearTimeout(timeout);
        }
    }, [expiresAt]);

    const refreshAuthToken = useCallback(async () => {
        // Skontrolujeme, či sa už vykonáva obnovovanie tokenu
        if (refreshingRef.current) return;
    
        // Nastavíme obnovovanie na true
        refreshingRef.current = true;
    
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/refresh-token`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
    
            if (!response.ok) throw new Error('Failed to refresh token');
    
            const data = await response.json();
            // console.log(data);
    
            // Nastavenie nového tokenu a expirácie
            setToken(data.access_token);
            setExpiresAt(data.expires_at);
            localStorage.setItem('authToken', data.access_token);
            localStorage.setItem('expiresAt', new Date(data.expires_at).toISOString());
    
        } catch (error) {
            console.error('Error refreshing token:', error);
            logoutRef.current();
        } finally {
            // Po dokončení obnovovania vrátime obnovovací stav späť na false
            refreshingRef.current = false;
        }
    }, [token]);
    

    useEffect(() => {
        const activityHandler = () => {
            // console.log("handler");
            if (token && expiresAt) {
                const now = new Date().getTime();
                if (new Date(expiresAt) - now < 12 * 60 * 60 * 1000) {
                    // console.log("Refresh");
                    refreshAuthToken();
                }
            }
        };

        window.addEventListener('mousemove', activityHandler);
        window.addEventListener('keydown', activityHandler);
        window.addEventListener('click', activityHandler);

        return () => {
            window.removeEventListener('mousemove', activityHandler);
            window.removeEventListener('keydown', activityHandler);
            window.removeEventListener('click', activityHandler);
        };
    }, [token, expiresAt, refreshAuthToken]);

    useEffect(() => {
        const savedToken = localStorage.getItem('authToken');
        const expiresAtLocal = localStorage.getItem('expiresAt');
        const user_id = localStorage.getItem('userID');
        const currentPath = window.location.pathname;
        const isPublicRoute = currentPath === '/register' || currentPath === '/login';

        if (savedToken && expiresAtLocal && !isTokenExpired(expiresAtLocal)) {
            setToken(savedToken);
            setExpiresAt(expiresAtLocal);
            setUserId(user_id);
            setIsAuthenticated(true);
        } else if (!isPublicRoute) {
            logoutRef.current();
        }
        setLoading(false);
    }, []);

    return (
        <AuthContext.Provider value={{ isAuthenticated, token, expiresAt, login, logout, loading, userId }}>
            {children}
        </AuthContext.Provider>
    );
};
